:root {
  --primary: #00060f;
  --primary-text: #fff;
  --secondary: #C28F19;
  --secondary-gradient: #B000FFB3;
  --secondary-text: #C28F19;
  --accent: #FFB300;
  --accent-text: #1A1200;
  --black: #000000;
  --green: #19C21F;
}

body {
  font-family: 'Prompt', sans-serif;
  color: var(--primary-text);
  background: var(--primary);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
